import React from "react"
import DateFomat from '../date-format'
import { Link } from "@reach/router"
import Webinars3 from "../webinar/block3"
import { useSelector } from 'react-redux'
import Tradeshows from "./trade-shows"
import InTheMedia from "./in-the-media"
import ExtentionIcon from "../extentions/extention2"
import { LangsNoLinks } from "../languages"
import './home.scss'
import arrow from "../../images/hp-arrow.svg"

const IndexPage = () => {

  // read the block data from state

  const blocks = useSelector(state => (state.app.blocks))
  const promoBlock = useSelector(state => (state.app.promoBlock))
  //console.log(promoBlock)

  const roles = useSelector(state => (state.user.user.roles))

  const showLatestFiles = roles.includes('medical_doctor_int_') || roles.includes('surgical_int')
  const showTopDownloads = !showLatestFiles
  //console.log(roles)


  const type1 = blocks.filter(b => b.type === "product_new_materials")
  const type2 = blocks.filter(b => (b.type === "product_with_image" || b.type === "free_text_with_image"))

  const m0 = type1.length > 0 ? type1[0] : false;
  const m1 = type1.length > 1 ? type1[1] : false;

  const q0 = type2.length > 0 ? type2[0] : false;
  const q1 = type2.length > 1 ? type2[1] : false;
  const q2 = type2.length > 2 ? type2[2] : false;
  const q3 = type2.length > 3 ? type2[3] : false;

  return (

    <>

      <div className="grid1 home">
        <div className="gb gb-1  ">
          {promoBlock && <BlockPromo {...promoBlock} />}
        </div>
        <div className="gb gb-2  ">
          {q0 && <BlockImage {...q0} />}
        </div>
        <div className="gb gb-3  ">
          {q1 && <BlockImage {...q1} />}
        </div>
        <div className="gb gb-4  ">
          {showTopDownloads && <TopDownloads />}
          {!showTopDownloads && <LatestFiles />}
        </div>
        <div className="gb gb-5  ">
          {q3 && <BlockImage {...q3} />}
        </div>
        <div className="gb gb-6  ">
          {m0 && <BlockProduct productId={m0.id} title={m0.name} />}
        </div>
        <div className="gb gb-7  ">
          {q2 && <BlockImage {...q2} />}
        </div>
        <div className="gb gb-8  ">
          {m1 && <BlockProduct productId={m1.id} title={m1.name} />}
        </div>


      </div>

      <div className="container-fluid " >

        <div className="row home   ml-0 pl-0">
          <div className="col">
            <Webinars3 />
          </div>
        </div>

        <div className="row home ml-0 pl-0 mt-5">

          <div className="col-xl-6 ml-0">
            <InTheMedia />
          </div>
          <div className="col-xl-6 mr-0">
            <Tradeshows />
          </div>
        </div>

      </div>
    </>


  )
}




const BlockImage = (props) => {

  //console.log( props )

  const { id, image, category_id, name, category_name, link } = props

  const inner = (
    <div id={id} className="block block-image" style={{ backgroundImage: `url(${image.url})` }}>
      <div className="mask">
        <div className="text">
          <h3>{name}</h3>
          <h4>{category_name} <img src={arrow} alt={category_name}></img> </h4>
        </div>
      </div>
    </div>
  )

  if (link) {
    return (
      <a className="block-link" href={link} target="__blank">
        <div id={id} className="block block-image" style={{ backgroundImage: `url(${image.url})` }}>
        </div>
      </a>
    )
  }

  const url = '/files/?product=' + id + '&category=' + category_id
  return (
    <Link className="block-link" to={url} >
      {inner}
    </Link>
  )
}


const BlockProduct = (props) => {

  const { productId, title } = props

  const data = useSelector(state => ({ ...state.app }))

  const { files, terms } = data

  const term = terms.find(t => t.id === productId)

  const items = files
    .filter(f => f.termsIds.includes(productId))
    .sort((a, b) => a.created < b.created ? 1 : -1)
    .slice(0, 20)

  //console.log( items )

  if (!items.length) return false

  const cards = items.map((item, i) => {
    //console.log(item)
    return (
      <React.Fragment key={item.id}>
        <CardProduct {...item} productId={productId} isFirst={i === 0} isLast={i === items.length - 1} />
      </React.Fragment>
    )
  })

  const t = title || term.label
  return (
    <div className="block block-product">
      {/* <div className="corner"></div> */}

      <div className="block-header">
        <h4 className="mb-0 p-0">New Materials</h4>
        <h3 className="ff-mont-bold fs-39px text-pink">{t}</h3>
      </div>

      <div className="block-body">
        {cards}
      </div>

    </div>
  )
}

const CardProduct = (props) => {
console.log(props)
  const { label, created, file, products, productId, id, isFirst, isLast, changed } = props

  const pid = productId ? productId : (products.length ? products[0].id : 0 /* probably section */)

  const url = pid ? '/files/?product=' + pid + '&id=' + id : '/files/?section=98&id=' + id;

  const classes = []
  if (isFirst) classes.push('card-first');
  if (isLast) classes.push('card-last');

  return (
    <div className={"card card-hp-product " + classes.join(' ')}>
      <div className="card-body">
        <span><Link to={url}>{label}</Link></span>


        <div className="grey d-flex flex-row justify-content-between">
          <span><DateFomat timestamp={changed} /></span>
          <div>
            <span><LangsNoLinks item={props} /></span>
            <span><ExtentionIcon file={file} /></span>
          </div>
        </div>
      </div>
    </div>
  )
}

const TopDownloads = () => {

  const data = useSelector(state => ({ ...state.app }))

  const { files, top_downloaded,RecentlyUpdatedFiles } = data
  let items = []
  let titleH4 = ''
  let titleH3 = ''
  //console.log(RecentlyUpdatedFiles)
  if (!RecentlyUpdatedFiles || RecentlyUpdatedFiles?.length === 0) {
    items = top_downloaded.map(t => {
      return files.find(f => f.id === t)
    })
    titleH4 = 'Top 10'
    titleH3 = 'Downloaded Files'
  }
  else {
    items = RecentlyUpdatedFiles.map(t => {
      //console.log(t);
      return files.find(f => f.id === t)
    })
    titleH4 = 'Recently'
    titleH3 = 'Updated Files'
  }
  


  if (!items.length) return false



  const cards = items.filter(item => item).map((item, i) => {
    //console.log(item, item.products,i)
    //console.log(item,i)
    return (
      <React.Fragment key={item.id}>
        <CardProduct {...item} isFirst={i === 0} isLast={i === items.length - 1} />
      </React.Fragment>
    )
  })

  //return false;

  return (
    <div className="block block-product">
      {/* <div className="corner"></div> */}
      <div className="block-header">
        <h4 className="mb-0 p-0">{titleH4}</h4>
        <h3 className="ff-mont-bold fs-39px text-purple">{titleH3}</h3>
      </div>

      <div className="block-body">
        {cards}
      </div>

    </div>
  )
}

const LatestFiles = () => {

  const data = useSelector(state => ({ ...state.app }))

  const { files, top_updated } = data

  //console.log(data)
  //console.log(top_updated)

  const items = top_updated.map(t => {
    return files.find(f => f.id === t)
  })


  if (!items.length) return false

  const cards = items.filter(item => item).map((item, i) => {
    //console.log(item, item.products,i)
    //console.log(item,i)
    return (
      <React.Fragment key={item.id}>
        <CardProduct {...item} isFirst={i === 0} isLast={i === items.length - 1} />
      </React.Fragment>
    )
  })

  //return false;

  return (
    <div className="block block-product">
      {/* <div className="corner"></div> */}
      <div className="block-header">
        <h4>Top 10</h4>
        <h3>Latest Files</h3>
      </div>

      <div className="block-body">
        {cards}
      </div>

    </div>
  )
}



const BlockPromo = (props) => {

  const { id, image, link, /*title , sub_title  */ } = props

  if (!image) return false
  //console.log(image);
  //console.log('link', link);
  return (

    <div id={id} className="block block-promo " >

      <div className="bg d-none d-md-block" style={{ backgroundImage: `url(${image.url})` }}  />
      {/* <div className="bg d-none d-md-block " style={{ backgroundImage: 'url(/online-brand-guidelines1.png)' }} /> */}
      <div className="bg d-block d-md-none " style={{ backgroundImage:  `url(${image.url})` }} />
      {/* <div className="bg d-block d-md-none " style={{ backgroundImage: 'url(/online-brand-guidelines-mobile1.png)' }} /> */}
      {/* <div className="gbg" />

      <div className="text1">
        <div className="subtitle">{sub_title}</div>
        <h3>{title}</h3>
      </div>

      <div className="text2">
        <a href={link.url} target="__blank">{link.title} <img src={arrow2} alt={link.title}></img></a>
      </div> */}

      <a href={link.url} target="__blank">
        <div className="link" />
      </a>


    </div>



  )


}


export default IndexPage
